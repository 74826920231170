.menu-navbar {
  background-color: var(--mantine-color-white);
  height: 100vh;
  width: 23rem;
  padding: var(--mantine-spacing-md);
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--mantine-color-gray-4);
}

.menu-header {
  padding: var(--mantine-spacing-md);
  padding-top: 0;
  margin-left: calc(var(--mantine-spacing-md) * -1);
  margin-right: calc(var(--mantine-spacing-md) * -1);
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  border-bottom: 1px solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.menu-links {
  flex: 1;
  margin-left: calc(var(--mantine-spacing-md) * -1);
  margin-right: calc(var(--mantine-spacing-md) * -1);
}

.menu-linksInner {
  padding-top: var(--mantine-spacing-xl);
  padding-bottom: var(--mantine-spacing-xl);
}

.menu-footer {
  margin-left: calc(var(--mantine-spacing-md) * -1);
  margin-right: calc(var(--mantine-spacing-md) * -1);
  border-top: 1rem solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.linkIcon {
  color: light-dark(var(--mantine-color-gray-6), var(--mantine-color-dark-2));
  width: 1.5rem;
  height: 1.5rem;
}

.menu-option {
  background-color: white;
  &:hover {
    background-color: var(--mantine-color-gray-3);
  }
}

.container--icon-logout {
  width: 1.4rem;
  height: 1.4rem;
}
