.wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 4rem;
}

.form {
  display: flex;
  align-items: center;
  width: 40rem;
  height: 7.4rem;
}

.wrapper--signup {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.is-zoom{
  zoom: 0.9;
}

.container__presentation {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.container__image_and_description--centered {
  display: "flex";
  flex-direction: "row";
  gap: "1rem";
  align-items: "center";
}

.container--baseline {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
}

.container--centered {
  display: flex;
  justify-content: center;
}

.container--presentation-cards {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  margin-top: 3.8rem;
}

.wrapper--presentation-card {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.container__create-account--title {
  margin-bottom: 2rem;
}

.container__create-account {
  width: var(--var-container-create-account-input-width-pc);
}

.text-input--width {
  width: calc(var(--var-container-create-account-input-width-pc) / 2);
}

.presentation_card--title {
  font-size: 0.2rem;
}

@media all and (max-width: 1501px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .container--baseline {
    justify-content: center;
  }

  .container__middle {
    display: flex;
    justify-content: center;
  }

  .text-input--width {
    width: calc(var(--var-container-create-account-input-width-phone) / 2);
  }

  .container__create-account {
    width: var(--var-container-create-account-input-width-phone);
  }

  .container--presentation-cards {
    margin-bottom: 6vh;
  }

  .container--baseline {
    align-items: center;
  }

  .container__middle {
    margin-bottom: 7rem;
  }
}

@media all and (max-width: 750px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .container__create-account {
    width: 70%;
    left: 50%;
    top: 50%;
  }
  html {
    font-size: 3vw;
  }

  .form {
    width: 93vw;
    height: 7rem;
  }

  .container--baseline {
    align-items: center;
  }

  .container--presentation-cards {
    margin-bottom: 6vh;
  }

  .container--presentation-text {
    display: "flex";
    flex-direction: "column";
    align-items: "center";
  }

  .container__middle {
    margin-bottom: 7rem;
  }
}
