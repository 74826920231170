.container__text-inputs--centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input--text {
  width: 28rem//var(--var-container-create-account-input-width-pc);
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
