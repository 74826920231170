.container__entity-tag-ner {
  background-color: #f1f1f1;
  border-radius: 7px;
  cursor: pointer;
}

.caret-transparent {
  caret-color: transparent;
}

.container--file-upload{
  border: dashed;
  border-color: #EFEFEF;
}

.wrapper--terminal-icon-size{
  width: 1.4rem;
  height: 1.4rem;
}

.wrapper--time-display{
  color: var(--mantine-color-gray-1);
}

.wrapper--icon-player-pause{
  background-color: red;
}

.wrapper--span-blue-filled{
  color: var(--mantine-color-blue-filled);
}

.wrapper--text-grey{
  color: var(--mantine-color-gray-1);
}