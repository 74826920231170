.header {
  position: absolute;
  display: block;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
}

.wrapper__logo--1 {
  padding: 1rem;
  overflow: visible;
}

.wrapper__logo--2 {
  margin-left: 2rem;
  position: absolute;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4.5vh;
}

.wrapper--inner {
  display: flex;
  gap: 1rem;
  align-items: stretch;
}

.text__ai--login {
  font-family: "Delicious Handrawn", cursive;
  font-size: 5rem;
  color: #228be6;
  caret-color: transparent;
}

.logo--selection {
  caret-color: transparent;
}
