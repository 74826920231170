.icon--copy {
  width: 1.3rem;
  height: 1.3rem;
  cursor: pointer;
}

.transparent-caret {
  caret-color: transparent;
}

.container--api-keys{
  width: 50%;
  padding: 2rem;
}