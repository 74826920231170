/* base styles */

._GzYRV {
  line-height: 1.2;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

._3eOF8 {
  margin-right: 5px;
  font-weight: bold;
}

._1MFti {
  cursor: pointer;
}

._f10Tu {
  font-size: 1.2em;
  margin-right: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

._1UmXx::after {
  content: "\25B8";
}

._1LId0::after {
  content: "\25BE";
}

._1pNG9 {
  margin-right: 5px;
}

._1pNG9::after {
  content: "...";
  font-size: 0.8em;
}

._2IvMF {
  background: white;
}

._2bkNM {
  margin: 0 10px;
  padding: 0;
}

/* default light style */
._1MGIk {
  font-weight: 500;
  margin-right: 5px;
  color: black;
}

._3uHL6 {
  color: var(--mantine-color-gray-1);
}

._2T6PJ {
  color: var(--mantine-color-gray-1);
}

._1Gho6 {
  color: var(--mantine-color-gray-1);
}

._vGjyY {
  color: #7A7A7A;
}

._1bQdo {
  color: var(--mantine-color-gray-1);
}

._3zQKs {
  color: var(--mantine-color-gray-1);
}

._1xvuR {
  color: var(--mantine-color-gray-1);
}

._oLqym {
  color: var(--mantine-color-gray-1);
}

._2AXVT {
  color: var(--mantine-color-gray-1);
}

._2KJWg {
  color: var(--mantine-color-gray-1);
}
