.user {
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-md);
  color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));

  &:hover {
    background-color: var(--mantine-color-gray-2);
  }
}

.container--icon-chevron {
  width: 1.4rem;
  height: 1.4rem;
}
