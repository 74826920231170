.control {
  font-weight: 500;
  display: block;
  width: 100%;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  color: var(--mantine-color-text);
  font-size: var(--mantine-font-size-sm);

  &:hover {
    background-color: var(--mantine-color-gray-2);
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  }
}

.link {
  font-weight: 500;
  display: block;
  text-decoration: none;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  padding-left: var(--mantine-spacing-md);
  margin-left: var(--mantine-spacing-xl);
  font-size: var(--mantine-font-size-sm);
  color: var(--mantine-color-gray-1);
  border-left: 1px solid
    light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));

  &:hover {
    background-color: var(--mantine-color-gray-2);
    color: light-dark(var(--mantine-color-black), var(--mantine-color-dark-0));
  }

  &[data-active] {
    &,
    &:hover {
      background-color: var(--mantine-color-gray-2);
      color: var(--mantine-color-blue-light-color);
    }
  }
}

.chevron {
  transition: transform 200ms ease;
}

.container--menu-icon {
  width: 1.4rem;
  height: 1.4rem;
}

.container--chevron-icon-rotated{
  transform: rotate(-90deg);
  transition: transform 200ms ease;
}
.container--chevron-icon{
  width: 1.4rem;
  height: 1.4rem;
  transform: none;
  transition: transform 200ms ease;
}