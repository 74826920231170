.container--profile {
  display: flex;
  flex-direction: row;
  gap: 7rem;
  margin-top: 1.5rem;
  margin-left: 5rem;
}

.container--account-details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.container__flex-row {
  display: flex;
  flex-direction: row;
}

.container--home {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  width: 100%;
  height: 100vh;
  margin: 0rem;
}
.container--home-options {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 1.5rem;
}

.options-text--clickable {
  cursor: pointer;
  caret-color: transparent;
}

.container_page-option {
  padding-left: 10rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer_home {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.wrapper--background-color-white {
  background-color: white;
}

.transparent-caret {
  caret-color: transparent;
}

.container--page-title {
  border-bottom: 1px solid #ececf1;
  margin-top: 2rem;
  width: 100%;
}
